import { Button, Link, Stack, Typography } from "@mui/material";
import {
  MEDIA,
  POEM,
  POEM_VAULT_LINK,
  SOLANA_EXPLORER,
  
} from "../../constants";
import { Column, Image, MediaStack, Row } from "../common";
import { LIGHT_SHADOW, formatAddress } from "../theme";
import { useHomeScrollContext } from "../../contexts";

const ASPECT_RATIO = 1280 / 728;
const WIDTH = { mobile: 200, desktop: 400 };
export default function About() {
  const { aboutRef } = useHomeScrollContext();
  return (
    <Stack ref={aboutRef}>
      <MediaStack
        sxBelow={{ flexDirection: "column-reverse" }}
        sxAbove={{ alignItems: "flex-start" }}
        spacing={6}
      >
        <Image
          sx={{ boxShadow: LIGHT_SHADOW }}
          variant="fixed-width"
          mobileWidth="100%"
          src={POEM}
          width={`${WIDTH.desktop}px`}
          height={`${ASPECT_RATIO * WIDTH.desktop}px`}
        />
        <Column spacing={4}>
          <Description />
        </Column>
      </MediaStack>
    </Stack>
  );
}

const bullets = [
  "The first community coin based on a fractional NFT",
  "The first official launch on Tinyman",
  "Equal distribution to community",
];

function Description() {
  return (
    <Column spacing={2}>
      <Typography variant="h1">
        The first community coin based on a fractional NFT
      </Typography>

      <Typography color="text.secondary">
        We split poem 'A Love Letter
        to Wen Bros' into a Million pieces that trade like normal TON Blockchain
        tokens. Each Wen token equals proportional ownership in Meow's poem.
      </Typography>

      <Column spacing={0.5}>
        <Typography variant="h4" color="text.primary">
          Wen was born to push the limits of TON Blockchain
        </Typography>

        {bullets.map((b, i) => (
          <Row alignItems="flex-start" spacing={1} key={i}>
            <Typography color="text.disabled">•</Typography>
            <Typography color="text.secondary"> {b} </Typography>
          </Row>
        ))}
      </Column>
    </Column>
  );
}

